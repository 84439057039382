import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import DataSourceNotion from './data-source-notion'
import DataSourceWebsite from './data-source-website'
import { fetchDataSource } from '@/service/common'
import i18n from '@/i18n/i18next-config'

export default function DataSourcePage() {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })

  const { data } = useSWR({ url: 'data-source/integrates' }, fetchDataSource)
  const notionWorkspaces = data?.data.filter(item => item.provider === 'notion') || []

  return (
    <div className='mb-8'>
      <div className='mb-2 text-sm font-medium text-gray-900'>{systemT('common.dataSource.add')}</div>
      <DataSourceNotion workspaces={notionWorkspaces} />
      <DataSourceWebsite />
    </div>
  )
}
